import { createStore } from "vuex";

function updateLocalStorage(cart, subtotal) {
  localStorage.setItem("cart", JSON.stringify(cart));
  localStorage.setItem("subtotal", subtotal);
}

export default createStore({
  state: {
    cart: [],
    subtotal: 0,
    APP_NAME: "",
    TOKEN: null,
    locale: "en",
  },
  getters: {
    productQty: (state) => (product) => {
      const item = state.cart.find((i) => i.id === product.id);

      if (item) return item.qty;
      else return null;
    },
  },
  mutations: {
    addToCart(state, product) {
      let item = state.cart.find(
        (i) => i.id === product.product.id && i.size === product.size
      );

      if (item) {
        if (item.isSizeable) {
          if (item.size != product.size) {
            state.cart.push({ ...product.product, qty: 1, size: product.size });
            if (
              item.sizePricing.find((i) => i.size == product.size)
                .discountPrice != null
            ) {
              state.subtotal += item.sizePricing.find(
                (i) => i.size == product.size
              ).discountPrice;
              return;
            } else {
              state.subtotal += item.sizePricing.find(
                (i) => i.size == product.size
              ).price;
              return;
            }
          } else if (item.size == product.size) {
            item.qty++;
            if (
              item.sizePricing.find((i) => i.size == product.size)
                .discountPrice != null
            ) {
              state.subtotal += item.sizePricing.find(
                (i) => i.size == product.size
              ).discountPrice;
              return;
            } else {
              state.subtotal += item.sizePricing.find(
                (i) => i.size == product.size
              ).price;
              return;
            }
          }
        } else {
          item.qty++;
          if (product.product.discountPrice != null) {
            state.subtotal += product.product.discountPrice;
          } else {
            state.subtotal += product.product.price;
          }
        }
      } else {
        state.cart.push({ ...product.product, qty: 1, size: product.size });
        if (product.product.isSizeable) {
          if (
            product.product.sizePricing.find((i) => i.size == product.size)
              .discountPrice != null
          ) {
            state.subtotal += product.product.sizePricing.find(
              (i) => i.size == product.size
            ).discountPrice;
          } else {
            state.subtotal += product.product.sizePricing.find(
              (i) => i.size == product.size
            ).price;
          }
        } else {
          if (product.product.discountPrice != null) {
            state.subtotal += product.product.discountPrice;
          } else {
            state.subtotal += product.product.price;
          }
        }
      }

      updateLocalStorage(state.cart, state.subtotal);
    },

    removeFromCart(state, product) {
      let item = state.cart.find(
        (i) => i.id === product.product.id && i.size === product.size
      );

      if (item) {
        if (item.qty > 1) {
          item.qty--;
          if (!item.isSizeable) {
            if (product.product.discountPrice != null) {
              state.subtotal -= product.product.discountPrice;
            } else {
              state.subtotal -= product.product.price;
            }
            return;
          }
          if (
            item.sizePricing.find((i) => i.size == product.size)
              .discountPrice != null
          ) {
            state.subtotal -= product.product.sizePricing.find(
              (i) => i.size == product.size
            ).discountPrice;
          } else {
            state.subtotal -= product.product.sizePricing.find(
              (i) => i.size == product.size
            ).price;
          }
        } else {
          let index = state.cart.indexOf(
            state.cart.find(
              (i) => i.id == product.product.id && i.size == product.size
            )
          );
          state.cart.splice(index, 1);
          if (item.isSizeable) {
            if (
              item.sizePricing.find((i) => i.size == product.size)
                .discountPrice != null
            ) {
              state.subtotal -= product.product.sizePricing.find(
                (i) => i.size == product.size
              ).discountPrice;
            } else {
              state.subtotal -= product.product.sizePricing.find(
                (i) => i.size == product.size
              ).price;
            }
          } else {
            if (product.product.discountPrice != null) {
              state.subtotal -= product.product.discountPrice;
            } else {
              state.subtotal -= product.product.price;
            }
          }
        }
      }

      updateLocalStorage(state.cart, state.subtotal);
    },
    updateCartFromLocalStorage(state) {
      if (localStorage.getItem("locale") == null) {
        localStorage.setItem("locale", "en");
      }
      const cart = localStorage.getItem("cart");
      const subtotal = localStorage.getItem("subtotal");
      const APP_NAME = localStorage.getItem("APP_NAME");
      const TOKEN = localStorage.getItem("_token");
      state.APP_NAME = APP_NAME;
      state.TOKEN = TOKEN;

      if (cart) {
        state.cart = JSON.parse(cart);
        state.subtotal = parseFloat(subtotal);
      }
    },
    setName(state, name) {
      state.APP_NAME = name;
      localStorage.setItem("APP_NAME", name);
    },
    changeLocale(state, locale) {
      state.locale = locale;
      localStorage.setItem("locale", locale);
    },
    logOut(state) {
      state.TOKEN = null;
      localStorage.removeItem("_token");
    },
  },

  actions: {},
  modules: {},
});
